/*!

=========================================================
* Vue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ArgonDashboard from './plugins/argon-dashboard'
import VueSweetalert2 from 'vue-sweetalert2';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import VCalendar from 'v-calendar';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCryptojs from 'vue-cryptojs'
import vuetify from '@/plugins/vuetify' // path to vuetify export

import VueClipboard from 'vue-clipboard2'
import VueDatePicker from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);
Vue.use(VueDatePicker);

// Options: You can set lang by default
Vue.use(VueDatePicker, {
    lang: 'fr'
});

Vue.use(VueCryptojs)

Vue.component('v-select', vSelect)

// If you don't need the styles, do not connect

Vue.use(VueSweetalert2);

Vue.prototype.$http = axios
Vue.prototype.$http.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token")
Vue.prototype.$http.defaults.headers.common['X-Requested-With'] = "XMLHttpRequest"

Vue.config.productionTip = false

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth, EmailAuthProvider } from "firebase/auth";

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyDcbKjsgTbe6ovNrYTGipf2ObPDTHg-dCU",
    authDomain: "bundaqrlink.firebaseapp.com",
    projectId: "bundaqrlink",
    storageBucket: "bundaqrlink.appspot.com",
    messagingSenderId: "248301189435",
    appId: "1:248301189435:web:57d88390f2b75ac5d69f1e",
    measurementId: "G-ZTW0V6FY92"
};

const app = initializeApp(firebaseConfig);

Vue.use(VueClipboard)

Vue.prototype.$auth = getAuth(app);

Vue.prototype.$provider = EmailAuthProvider.PROVIDER_ID

Vue.use(VCalendar)
Vue.use(ArgonDashboard)
new Vue({
    vuetify,
    router,
    axios,
    VueAxios,
    render: h => h(App)
}).$mount('#app')