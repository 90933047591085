import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import LogoutLayout from '@/layout/LogoutLayout'
Vue.use(Router)

export default new Router({
    linkExactActiveClass: 'active',
    mode: 'history',
    routes: [{
            path: '/',
            redirect: 'list-reservasi',
            component: DashboardLayout,
            children: [{
                path: '/list-reservasi',
                name: 'List Reservasi',
                component: () =>
                    import ( /* webpackChunkName: "demo" */ './views/HalamanQR.vue')
            }, ]
        },
        {
            path: '/',
            component: LogoutLayout,
            children: [{
                path: '/logout',
                name: 'Logout',
                component: () =>
                    import ( /* webpackChunkName: "demo" */ './views/logout.vue')
            }, ]
        },
        {
            path: '/',
            redirect: 'login',
            component: AuthLayout,
            children: [{
                path: '/login',
                name: 'login',
                component: () =>
                    import ( /* webpackChunkName: "demo" */ './views/Login.vue')
            }]
        }
    ]
})